export const META_TITLE = process.env.REACT_APP_META_TITLE ? process.env.REACT_APP_META_TITLE : "";
export const META_DESCRIPTION = process.env.REACT_APP_META_DESCRIPTION ? process.env.REACT_APP_META_DESCRIPTION : "";

export const CLINIC_PHONE = process.env.REACT_APP_CLINIC_PHONE ? process.env.REACT_APP_CLINIC_PHONE : "";
export const CLINIC_PHONE_URL = process.env.REACT_APP_CLINIC_PHONE_URL ? process.env.REACT_APP_CLINIC_PHONE_URL : "";

export const API_URL = process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : "";
export const STORAGE_URL = process.env.REACT_APP_STORAGE_URL ? process.env.REACT_APP_STORAGE_URL : "";

export const PRIVACY_POLICY_LINK = process.env.REACT_APP_PRIVACY_POLICY_LINK ? process.env.REACT_APP_PRIVACY_POLICY_LINK : "";
export const USER_AGREEMENT_LINK = process.env.REACT_APP_USER_AGREEMENT_LINK ? process.env.REACT_APP_USER_AGREEMENT_LINK : "";

export const YM_COMMON_COUNTER_ID = process.env.REACT_APP_YAMETRIKA_COMMON_COUNTER_ID
    ? parseInt(process.env.REACT_APP_YAMETRIKA_COMMON_COUNTER_ID)
    : undefined;
export const YM_COMMON_APPOINTMENT_CREATION_SUCCESS = process.env.REACT_APP_YAMETRIKA_COMMON_APPOINTMENT_CREATION_SUCCESS;

export const YM_CLIENT_COUNTER_ID = process.env.REACT_APP_YAMETRIKA_CLIENT_COUNTER_ID
    ? parseInt(process.env.REACT_APP_YAMETRIKA_CLIENT_COUNTER_ID)
    : undefined;
export const YM_CLIENT_APPOINTMENT_CREATION_SUCCESS = process.env.REACT_APP_YAMETRIKA_CLIENT_APPOINTMENT_CREATION_SUCCESS;
